body {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 400;
}

.page-wrapper-full {
	color: #333333;
}

h1 {
	margin-top: 0em;
	margin-bottom: 0.5em;
	font-size: 2.25rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h2 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	font-size: 1.875rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h3 {
	margin-top: 1.5em;
	margin-bottom: 0.5em;
	font-size: 1.5rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h4 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	font-size: 1.25rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h5 {
	margin-top: 1.375em;
	margin-bottom: 0.75em;
	font-size: 0.9375rem;
	line-height: 1.5em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h6 {
	margin-top: 1.5em;
	margin-bottom: 0.875em;
	font-size: 0.8125rem;
	line-height: 1.5em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

p {
	margin-top: 0em;
	margin-bottom: 0.75em;
}

a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #001489;
	background-color: #fff;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.25em;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

li {
	margin-bottom: 0.25em;
}

label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 700;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1.25em;
	border-left: 4px solid rgba(0, 0, 0, 0.5);
	background-color: rgba(0, 0, 0, 0.1);
	font-size: 1.0625em;
	line-height: 1.5em;
}

.button,
.button.primary,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 0.5em;
	margin-right: 10px;
	margin-bottom: 0.5em;
	padding: 0.5em 1.5em;
	border: 3px solid #001489;
	background-color: #001489;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff !important;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05em;
	text-decoration: none;
}

.primary,
.secondary,
.tertiary,
button[type="submit"],
#jobs_module.cms_form .cms_form_button {
	padding: 0.5em 1.5em !important;
	border: 3px solid #001489 !important;
}

.button:hover {
	border-color: #ba0c2f;
	background-color: #ba0c2f;
	color: #fff !important;
}

.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover,
#jobs_module.cms_form .cms_form_button:hover {
	border-color: #ba0c2f !important;
	background-color: #ba0c2f !important;
	color: #fff !important;
}

.button.secondary,
.secondary,
button[type="submit"].secondary {
	border: 3px solid transparent !important;
	background-color: #28313b;
	color: #fff !important;
}

.button.secondary:hover {
	border-color: transparent;
	background-color: #585c5f;
	color: #fff;
}

.secondary:hover,
button[type="submit"].secondary:hover {
	background-color: #585c5f !important;
	border-color: transparent !important;
}

.button.tertiary,
.tertiary,
button[type="submit"].tertiary {
	color: #fff !important;
	border-color: #373a3d !important;
	background-color: #373a3d;
}

.button.tertiary:hover {
	border-color: #586879;
	background-color: #586879;
}

.tertiary:hover,
button[type="submit"].tertiary:hover {
	border-color: #586879 !important;
	background-color: #586879 !important;
}

.button.header-search-button {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 2rem;
	height: 100%;
	min-height: 2rem;
	margin-top: 0em;
	margin-right: 0px;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	float: right;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	background-color: #ba0c2f;
	background-image: url('/20160710165515/assets/images/search.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.8125rem;
	line-height: 1em;
	text-transform: uppercase;
	text-indent: -9999px;
}

.button.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.button.slider-button {
	width: auto;
	margin-top: 1em;
	margin-bottom: 0.25em;
	padding: 0.5em 1em;
	float: left;
	border: 2px none #aaa;
	box-shadow: none;
	color: #fff !important;
	background-color: #ba0c2f;
	font-size: 0.85rem;
	text-align: center;
}

.button.slider-button:hover {
	border-color: #ab1b20;
	background-color: #001489;
	box-shadow: none;
	color: #fff;
}

.container.footer {
	display: none;
}

.nav-section {
	background-color: transparent;
	color: #005687;
	position: relative;
}

.nav-container {
	position: relative;
	height: 40px;
	max-width: 100%;
	background-image: -webkit-linear-gradient(270deg, #005687, #005687);
	background-image: linear-gradient(180deg, #005687, #005687);
}

.nav-link {
	display: inline-block;
	margin-right: 1px;
	padding: 1rem 1.5rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	box-shadow: 0 -3px 0 0 transparent, 1px 0 0 0 hsla(0, 0%, 50%, 0.25);
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.375em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
}

.nav-link:hover {
	background-color: rgba(0, 0, 0, 0.1);
	box-shadow: 0 -3px 0 0 rgba(0, 0, 0, 0.5), 1px 0 0 0 hsla(0, 0%, 50%, 0.25);
	color: #fff;
}

.nav-link.w--current {
	display: none;
	height: 40px;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -3px 0 0 #fff, 1px 0 0 0 hsla(0, 0%, 50%, 0.25);
	color: #fff;
	text-transform: uppercase;
}

.nav-link.w--current:hover {
	background-color: rgba(0, 0, 0, 0.1);
	color: #fff;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 50px;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-left: 1px solid rgba(20, 25, 82, 0.85);
	background-image: -webkit-linear-gradient(270deg, #001489, #001489);
	background-image: linear-gradient(180deg, #001489, #001489);
}

.logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5em;
	float: left;
	border-bottom-style: none;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
	background-color: transparent;
}

.logo-container {
	float: left;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.logo:hover {
	color: #fff;
}

.website-logo-container {
	width: auto;
	max-width: 200px;
	text-align: right;
	margin-left: 50px;
}

.website-logo {
	margin-top: 0.2em;
	max-height: 120px;
	width: auto;
	margin-bottom: 0.5em;
}

.website-tagline {
	margin: 0.7rem 0px 0.5rem 18px;
	padding: 0px;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: 700;
	text-transform: uppercase;
	clear: both;
}

.logo.w--current {
	margin-top: 0.5em;
	margin-bottom: 0.35em;
	margin-left: 0em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.footer-section {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1250px;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.row.footer-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.row.footer-row.top {
	display: none;
}

.column {
	width: 100%;
	padding-bottom: 0.25rem;
	padding-left: 0px;
	float: left;
	background-image: none;
}

.image-wrapper {
	padding-top: 55%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
}

.logo-image {
	width: auto;
	height: 5rem;
	float: left;
}

.footer-signoff-section {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	background-color: #222527;
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	color: #cccdce;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 0.25rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #606060;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

#GRIPFooterLogoText {
	color: #cccdce !important;
}

#GRIPFooterLogo a {
	background-color: transparent;
}

#GRIPFooterLogoImg {
	color: #fff;
	font-family: inherit;
	background-color: #373a3d;
}

.footer-column-wrap {
	max-width: 62%;
	margin-bottom: 1em;
	padding-right: 1rem;
	padding-left: 1rem;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.footer-column-wrap.quicklinks {
	display: none;
}

.footer-column-wrap._1 {
	max-width: 25%;
	padding-left: 0.8rem;
}

.footer-column-wrap._2 {
	width: 210px;
	max-width: 25%;
	padding-left: 0.8rem;
}

.footer-column-wrap._3 {
	max-width: 35%;
	padding-left: 0.8rem;
}

.footer-column-title {
	margin-top: 0.5em;
	color: #fff;
	font-size: 1.125rem;
	text-transform: uppercase;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: right;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	text-transform: none;
}

.header-contents-wrap {
	position: relative;
	background-color: #f2f3f4;
	color: #2a317d;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 1em 0.125rem;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	height: 50px;
	background-color: #001489;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.header-search-field {
	width: 200px;
	height: 2rem;
	margin-bottom: 0em;
	padding-right: 3rem;
	float: left;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	border: 0px none transparent;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #222;
	font-size: 0.75em;
}

.form-field.header-search-field:focus {
	border: 0px none transparent;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #167c28;
	color: #fff;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #ba0c2f;
	color: #fff;
}

.secondary-nav-list {
	display: none;
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-bottom: 0em;
	float: left;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	letter-spacing: 0.01em;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.inside-row {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.left-nav-column {
	width: 22%;
	padding-top: 0rem;
	padding-right: 0px;
	padding-left: 0px;
	background-image: none;
}

.main-content-column {
	width: 78%;
	padding-right: 0px;
	padding-left: 0px;
	background-color: #fff;
	box-shadow: none;
	font-family: Ubuntu, Helvetica, sans-serif;
	min-height: 400px;
}

.main-content-column.right-sidebar-page {
	width: 75%;
}

.inside-row-nested {
	padding-bottom: 2rem;
	background-color: transparent;
}

.right-sidebar-column {
	margin-top: 2em;
	border-left: 3px solid hsla(0, 0%, 50%, 0.1);
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	box-shadow: none;
}

.left-nav-list-item {
	margin-bottom: 0em;
	box-shadow: none;
}

.left-nav-list-link {
	display: block;
	width: 100%;
	min-height: 46px;
	padding: 0.7rem 1.5rem 0.5rem 1.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 2px none rgba(0, 0, 0, 0.08);
	background-image: url('/20160710165515/assets/images/side-nav-arrow-06-06_transp.svg');
	background-position: 100% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: background-color 200ms ease, color 50ms ease, background-position 200ms ease;
	transition: background-color 200ms ease, color 50ms ease, background-position 200ms ease;
	color: #000;
	font-size: 0.95rem;
	line-height: 1.4em;
	font-weight: 600;
	text-decoration: none;
	background-color: transparent;
}

.left-nav-list-link:hover {
	background-image: url('/20160710165515/assets/images/blue-official-arrow-02_1.png');
	background-position: 100% 50%;
	background-size: 1065px;
	background-repeat: no-repeat;
	color: #fff;
}

.left-nav-list-link.selected,
.left-nav-list-link.w--current {
	background-image: url('/20160710165515/assets/images/blue-official-arrow-02_3.png');
	background-position: right;
	background-size: 1065px;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #fff;
	font-weight: 700;
	text-decoration: none;
	background-color: #001489;
}


.left-nav-list-link.selected:hover,
.left-nav-list-link.w--current:hover {
	background-color: transparent;
	background-image: url('/20160710165515/assets/images/blue-official-arrow-02_2.png');
	background-position: 100% 50%;
	background-size: 1065px;
}

.left-nav-list ul {
	margin-bottom: 0px;
}

.left-nav-column .section-title {
	text-transform: uppercase;
	display: block;
	width: 100%;
	min-height: 46px;
	padding: 1.15rem 1.5rem 0.5rem 1.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 3px solid #2a317d;
	-webkit-transition: background-color 200ms ease, color 50ms ease, background-position 200ms ease;
	transition: background-color 200ms ease, color 50ms ease, background-position 200ms ease;
	color: #001489;
	font-size: 1.35rem;
	line-height: 1.4em;
	font-weight: 600;
	text-decoration: none;
	margin: 0px 0px 1px 0px;
	letter-spacing: normal;
}

.left-nav-column .section-title a {
	font-weight: inherit;
	line-height: inherit;
	font-size: inherit;
	content: inherit;
	font-family: inherit;
	border: 0px;
	box-shadow: none;
	text-decoration: none;
	background-color: transparent;
}

.left-nav-column .section-title a:hover {
	background-color: transparent;
	background-image: none;
	color: #ba0c2f;
}

.left-nav-column .section-title a.w--current {
	background-image: none;
}

.left-nav-list-link.grandchild-link {
	padding-left: 2.15rem;
	font-size: 0.9rem;
}

.left-nav-list-link.greatgrandchild-link {
	padding-left: 3rem;
	font-size: 0.83rem;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
}

.left-nav-list-link.greatgrandchild-link ~ .left-nav-nested-list a.greatgrandchild-link {
	padding-left: 3.75rem;
	font-size: 0.79rem;
}

.right-sidebar-column .box {
	position: relative;
	margin-right: 1rem;
	margin-bottom: 2.441em;
	margin-left: 1em;
	padding-top: 0em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-sidebar-column h2,
.right-sidebar-column h3,
.right-sidebar-column h4,
.right-sidebar-column h5,
.right-sidebar-column h6 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-size: 1.25rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.01em;
	font-family: 'Open Sans', sans-serif;
	color: #444;
}

.right-sidebar-column .box.partner-document .contentbox_item_image {
	width: 24px;
	padding-right: 10px;
}

.right-sidebar-column .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

.sidebar-list {
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	box-shadow: none;
}

.secondary-nav-icon {
	width: 1.375em;
	height: 1.375em;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.slider-section {
	position: relative;
}

.slider {
	display: block;
	height: 450px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-arrow {
	bottom: -340px;
	width: 45px;
	height: 50px;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
	background-color: #9ea2a2;
	opacity: 1;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 2rem;
}

.slider-arrow:hover {
	background-color: #ba0c2f;
	background-image: none;
	opacity: 1;
}

.slider-arrow.right {
	border-radius: 25px 0px 0px 25px;
	background-image: -webkit-linear-gradient(270deg, #9ea2a2, #9ea2a2);
	background-image: linear-gradient(180deg, #9ea2a2, #9ea2a2);
	opacity: 1;
}

.slider-arrow.right:hover {
	background-image: -webkit-linear-gradient(270deg, #ba0c2f, #ba0c2f);
	background-image: linear-gradient(180deg, #ba0c2f, #ba0c2f);
	opacity: 1;
}

.slider-indicator {
	z-index: 2;
	height: 2.5rem;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.6rem;
}

.main-section {
	background-color: #9ea2a2;
	display: block;
}

.slider-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-position: 0px 0px, 0px 0px, 50% 66%, 0% 0px;
	background-size: auto, auto, cover, cover;
	background-repeat: repeat, repeat, no-repeat, no-repeat;
}

.slider-image.slide-2 {
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.slider-image.slide-3 {
	background-position: 0px 0px, 0px 0px, 0% 53%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, repeat;
}

.slider-caption-wrap {
	position: relative;
	top: 99%;
	z-index: 1000;
	display: none;
	overflow: hidden;
	width: 100%;
	max-height: 285px;
	min-height: 180px;
	margin-right: 0%;
	margin-left: 0%;
	padding-top: 0.75rem;
	padding-bottom: 1.5rem;
	background-image: -webkit-linear-gradient(270deg, hsla(210, 8%, 95%, 0.9), hsla(210, 8%, 95%, 0.9));
	background-image: linear-gradient(180deg, hsla(210, 8%, 95%, 0.9), hsla(210, 8%, 95%, 0.9));
	-webkit-transform: translate(0px, -63%);
	-ms-transform: translate(0px, -63%);
	transform: translate(0px, -63%);
	color: #fff;
	text-align: center;
}

.inside-page-header-content-wrap {
	padding: 0.5rem 1.5rem 0.25em;
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
	background-color: #fff;
}

.inside-page-header-content-wrap.entry {
	border-bottom: 3px solid hsla(0, 0%, 50%, 0.1);
	background-color: #fff;
}

.inside-page-header-section {
	position: relative;
	display: none;
	min-height: 120px;
	background-color: #373a3d;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 100%;
	margin-top: -50px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 3rem;
	padding-bottom: 4rem;
	background-color: #fff;
	background-image: -webkit-linear-gradient(270deg, #f2f3f4, #f2f3f4);
	background-image: linear-gradient(180deg, #f2f3f4, #f2f3f4);
}

.inside-content-beckground.right-column .main-content-column {
	width: 100%;
}

.inside-content-beckground.no-columns .main-content-column {
	width: 100%;
}

.inside-content-beckground.no-columns .main-content-wrap.nested-content-wrap {
	padding-top: 2rem;
	padding-right: 8rem;
	padding-left: 8rem;
}

.inside-content-beckground.right-column .right-sidebar-column {
	padding-right: 10px;
	padding-left: 10px;
}

.main-content-wrap {
	padding-left: 1.5rem;
}

.main-content-wrap.nested-content-wrap {
	padding: 2rem;
	background-color: #fff;
	box-shadow: none;
}

.main-content-wrap.nested-content-wrap.full {
	padding-top: 2rem;
	padding-right: 8rem;
	padding-left: 8rem;
}

.header-menu-inside-wrap {
	display: block;
	height: 40px;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.intro_text_style,
.intro-text-style {
	margin-bottom: 1em;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #444;
	font-size: 1.1875em;
	line-height: 1.5em;
	font-weight: 600;
}

.slider-arrow-icon {
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.news-content-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1.25rem;
	padding-bottom: 1.35rem;
	background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.85), hsla(0, 0%, 100%, 0.85)), -webkit-linear-gradient(270deg, #fff, #fff), -webkit-linear-gradient(270deg, #f2f3f4, #f2f3f4);
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.85), hsla(0, 0%, 100%, 0.85)), linear-gradient(180deg, #fff, #fff), linear-gradient(180deg, #f2f3f4, #f2f3f4);
	box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.15);
}

.slider-caption-title {
	width: 100%;
	padding-right: 0.5rem;
	float: left;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.6rem;
	line-height: 2.1rem;
	font-weight: 600;
	text-align: left;
}

.footer-links-list {
	margin-top: -0.25em;
	border-top: 1px none hsla(0, 0%, 100%, 0.1);
}

.footer-links-list-item {
	margin-bottom: 1px;
	box-shadow: none;
}

.footer-social-icon-link {
	margin-right: 8px;
	margin-bottom: 8px;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 100%, 0.25);
	opacity: 0.55;
}

.footer-social-icon-link:hover {
	opacity: 1;
}

.footer-social-icon-image {
	width: 28px;
	height: 28px;
	padding: 0px;
}

.left-nav-list-content-wrap {
	overflow: hidden;
	padding-top: 4.85rem;
	padding-bottom: 1.5em;
	border-right: 1px none rgba(0, 0, 0, 0.15);
	background-color: hsla(180, 2%, 63%, 0.25);
}

.dropdown-list-section-title {
	margin-bottom: 0rem;
	padding: 0.5rem;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
	line-height: 1.25em;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.footer-link {
	color: #9ea1a1;
	text-decoration: none;
	background-color: transparent;
}

.footer-link:hover {
	color: #fff;
}

.footer-link.footer-list-item-link {
	display: block;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
	color: #cccdce;
	text-decoration: underline;
	text-transform: capitalize;
	background-color: transparent;
}

.footer-link.footer-list-item-link:hover {
	color: #fff;
}

.footer-link.footer-list-item-link.hyperlink {
	text-decoration: underline;
}

.right-sidebar-column a,
.main-content-wrap a,
.left-sidebar-custom a,
.link {
	text-decoration: none;
	border-bottom: 1px solid rgba(84, 159, 196, 0.35);
	color: #001489;
	cursor: pointer;
}

.right-sidebar-column a:hover,
.main-content-wrap a:hover,
.left-sidebar-custom a:hover,
.link:hover {
	text-decoration: none;
	border-bottom-style: solid;
	border-bottom-color: #549fc4;
	color: #28313b;
}

.right-sidebar-column a.primary:hover,
.main-content-wrap a.primary:hover,
.left-sidebar-custom a.primary:hover {
	color: #fff !important;
	text-decoration: none;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.breadcrumb-link {
	color: #001489;
	cursor: pointer;
	background-color: transparent;
}

.link.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #9ea2a2;
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child a.breadcrumb-link {
	border-bottom: 0px;
}

.md-doctor-form-reveal-wrap {
	display: none;
	overflow: hidden;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: rgba(0, 0, 0, 0.15);
}

.md-advanced-search-tooltip-popup {
	position: absolute;
	left: -1rem;
	bottom: 100%;
	display: none;
	width: 155px;
	margin-bottom: 0.5rem;
	padding: 0.75rem 1.125rem 1.125rem 0.875rem;
	border-radius: 3px;
	background-color: #28313b;
	box-shadow: 0 0 20px 5px rgba(40, 49, 59, 0.25);
	color: #fff;
	font-size: 0.8125em;
	line-height: 1.375em;
}

.md-patient-comments-reveal-more {
	overflow: hidden;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.table-style-embed {
	margin-top: 0.5rem;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 50px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px solid rgba(20, 25, 82, 0.85);
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 50px;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle:hover {
	background-color: #0b358e;
	box-shadow: 0 -3px 0 0 #ba0c2f;
	color: hsla(0, 0%, 65%, 0.8);
}

.nav-drop-wrap:hover .nav-drop-toggle {
	background-color: #0e3c9c;
	background-image: none;
	box-shadow: 0 -3px 0 0 #ba0c2f;
	color: #fff;
}

.nav-drop-icon {
	margin-right: 2rem;
	color: #cecccc;
	font-size: 0.75em;
	line-height: 1.25em;
}

.nav-drop-list {
	display: none;
	left: 0px;
	top: 100%;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #33383b;
	color: #fff;
}

.nav-drop-list.w--open {
	display: block;
	padding: 0.5em 0.5rem;
}

.nav-drop-list-link {
	display: block;
	padding: 0.5em 1.5em 0.5em 0.5rem;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
	font-weight: 600;
	text-decoration: none;
	background-color: #33383b;
}

.link-block a.nav-drop-list-link {
	background-color: #33383b;
	cursor: pointer;
	color: #cccdce;
}

.link-block a.nav-drop-list-link:hover {
	background-color: #9ea1a1;
	color: #000;
	font-weight: 600;
}

.nav-drop-list-link:hover {
	background-color: #9ea1a1;
	color: #000;
	font-weight: 600;
}

.nav-drop-list-link.w--current {
	background-color: #9ea1a1;
	color: #000;
	font-weight: 600;
}

.nav-drop-toggle-link {
	width: 100%;
	height: 50px;
	padding: 0.85rem 3rem 0.85rem 1rem;
	background-image: none;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.375em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
}

span.nav-drop-toggle-link {
	cursor: default;
}

.nav-drop-wrap:not(.has-dropdown) .nav-drop-toggle-link {
	padding-right: 1rem;
}

.nav-drop-toggle-link:hover {
	background-image: none;
	color: #fff;
}

.nav-drop-toggle-link.selected,
.nav-drop-toggle-link.w--current {
	background-color: #0e3c9c;
	background-image: none;
	box-shadow: 0 -3px 0 0 #ba0c2f;
}

.nav-drop-toggle-link.selected:hover,
.nav-drop-toggle-link.w--current:hover {
	background-color: #0e3c9c;
	background-image: none;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1300px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-image: -webkit-linear-gradient(270deg, #f2f3f4, #f2f3f4);
	background-image: linear-gradient(180deg, #f2f3f4, #f2f3f4);
	align-items: flex-start;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.71rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 0.71rem;
}

.secondary-header-item-cta-link {
	width: 200px;
	height: 2rem;
	margin-left: 0.85rem;
	padding: 0.5em 3em 0.5em 1.5em;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	background-color: #ba0c2f;
	background-image: url('/20160710165515/assets/images/keyboard-right-arrow-button-1.svg');
	background-position: 85% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: none;
	text-decoration: none;
}

.secondary-header-item-cta-link:hover {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
}

.header-search-wrap {
	margin-bottom: 0px;
	margin-left: 0.85rem;
}

.header-search-form {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: rgba(0, 0, 0, 0.09);
}

.header-search-form .fieldset {
	display: flex;
	margin: 0px;
	padding: 0px;
}

.header-search-form .fieldset div {
	display: flex;
}

.slider-caption-title-wrap {
	margin-bottom: 0.5rem;
	padding-right: 10px;
	padding-left: 0px;
	text-align: left;
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #28313b;
}

.footer-social-icons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
}

.footer-contact-label {
	display: inline-block;
	min-width: 64px;
}

.ordered-list.alpha-order {
	list-style-type: lower-alpha;
}

.ordered-list.roman-order {
	list-style-type: lower-roman;
}

.nav-drop-list-new {
	overflow: hidden;
}

.nav-drop-list-new.w--open {
	left: 0px;
	top: 100%;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #000;
	color: #fff;
}

.nav-drop-list-element {
	position: absolute;
	left: 0%;
	top: 100%;
	z-index: 3;
	display: none;
	overflow: hidden;
	width: 100%;
	min-width: 200px;
	margin: 0px;
	padding: 0.5rem;
	background-color: #000;
	box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.25);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
}

.nav-drop-background {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: none;
	background-color: rgba(0, 0, 0, 0.25);
}

.special-notice-alert-box {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	box-shadow: none;
}

.special-notice-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	padding-top: 4px;
	padding-bottom: 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 3px solid #dbc368;
	background-color: #fff;
	color: #151618;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 600;
	text-transform: none;
}

.special-notice-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	padding-right: 6px;
	padding-left: 6px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #ba0c2f;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 700;
	text-transform: uppercase;
}

.special-notice-text {
	display: inline-block;
	padding-right: 6px;
	padding-left: 6px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.8rem;
	font-weight: 700;
	text-transform: none;
}

.special-notice-text p {
	margin-bottom: 0;
}

.news-container {
	display: block;
	width: 98%;
	margin-right: auto;
	margin-left: auto;
}

.news-content-wrapper {
	display: block;
}

.find-your-squadron-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #001489;
}

.squadron-container {
	display: block;
	width: 225px;
	margin-right: 15px;
	padding-top: 20px;
	float: left;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.squadron-container.zipcode {
	width: 355px;
	margin-left: 25px;
	padding-top: 15px;
	padding-bottom: 5px;
}

.i-am-a-text {
	display: block;
	width: auto;
	padding: 0.2rem 11px 0.2rem 10px;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	color: #f2f3f4;
	font-size: 0.85rem;
	text-align: left;
}

.go-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 42px;
	height: 34.75px;
	margin-right: 5px;
	margin-left: -3px;
	padding: 0.25rem 0.55rem 0.25rem 0.35rem;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-left: 3px none #beddeb;
	border-radius: 0px 50px 50px 0px;
	background-color: #ba0c2f;
	background-image: none;
	color: #f2f3f4;
	font-size: 0.85rem;
	font-weight: 600;
	text-transform: uppercase;
}

.go-button:hover {
	background-color: #6b7070;
	background-image: none;
	color: #f2f3f4;
}

.zip-text {
	width: 125px;
	height: 34px;
	margin-bottom: 0px;
	padding: 0.3rem 6px;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	color: #222;
	font-size: 0.85rem;
	line-height: 1.5em;
	text-align: center;
	float: left;
}

.zip-text:focus {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
}

.zipcode-form-block {
	margin-bottom: 0px;
}

.zip-code-text-field {
	display: block;
}

.news-image {
	width: 100%;
	height: auto;
}

.news-caption-text {
	margin-top: 0.75rem;
	margin-bottom: 0em;
	color: #28313b;
	font-size: 0.9rem;
	line-height: 1.5em;
}

.news-caption-text:hover {
	color: #28313b;
}

.donate-buttons-section {
	display: block;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 30px;
	padding-bottom: 40px;
	background-color: #f1f1f1;
}

.donate-button-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	padding-top: 8px;
	padding-bottom: 8px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 30px;
	background-color: #ba0c2f;
}

.donate-button-container:hover {
	background-color: #001489;
}

.donate-text {
	margin-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
}

.donate-text strong {
	text-transform: uppercase;
}

.donate-text p {
	margin-bottom: 0;
	display: inline-block;
}

.main-content-wrap a.donate-button-block {
	display: inline-block;
	margin: 5px auto;
	border-radius: 30px;
	background-color: #ba0c2f;
	padding-top: 14px;
	padding-bottom: 10px;
	padding-left: 60px;
	padding-right: 60px;
	text-align: center;
	color: #fff;
	font-size: 1rem;
	text-transform: uppercase;
	text-decoration: none;
	border-bottom: none;
}

.main-content-wrap a.donate-button-block:hover {
	background-color: #001489;
}

.main-content-wrap a.donate-button-block:after {
	content: '';
	display: inline-block;
	background-image: url('/20160710165515/assets/images/circle-arrow-02.png');
	width: 30px;
	height: 30px;
	background-position: 0 0;
	background-size: cover;
	background-repeat: repeat-y;
	vertical-align: middle;
	margin-left: 12px;
	margin-top: -3px;
}

.content-box-section {
	position: static;
	top: 575px;
	display: block;
	width: 100%;
	max-width: 1300px;
	margin-top: 12rem;
	margin-right: auto;
	margin-left: auto;
	background-image: none;
}

.content-box-section-container {
	width: 98%;
}

.cb {
	width: 33.33%;
	padding: 1rem;
	float: left;
}

.cb-wrapper {
	padding-bottom: 4rem;
}

.custom-link.cb {
	background-color: transparent;
	text-decoration: none;
}

.cb-content {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-left: 1.5rem;
	float: none;
	border-top: 4px solid #ba0c2f;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	-webkit-transition: opacity 300ms ease, all 300ms ease;
	transition: opacity 300ms ease, all 300ms ease;
	position: relative;
}

.cb-content:hover {
	background-image: -webkit-linear-gradient(270deg, #fff, #fff), -webkit-linear-gradient(270deg, hsla(210, 8%, 95%, 0.54), hsla(210, 8%, 95%, 0.54));
	background-image: linear-gradient(180deg, #fff, #fff), linear-gradient(180deg, hsla(210, 8%, 95%, 0.54), hsla(210, 8%, 95%, 0.54));
	box-shadow: 1px 0 12px 2px rgba(0, 0, 0, 0.2);
	opacity: 1;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.cb-image {
	width: 55%;
	padding-left: 1.35rem;
	float: right;
	background-image: none;
}

.cb-text-heading {
	display: inline-block;
	width: 45%;
	padding-top: 0px;
	padding-right: 0.35rem;
	float: left;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.6rem;
	line-height: 2rem;
	font-weight: 600;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.cb-text-heading span {
	background-color: #fffd;
	font-family: inherit;
}

.cb-text-heading div.sub-heading {
	margin-top: 0.5rem;
}

.cb-text-heading div.sub-heading p {
	font-size: 1.1rem;
	color: #585c5f;
	line-height: 1.3rem;
}

.custom-link:hover .cb-text-heading {
	color: #28313b;
}

.column-wrapper {
	width: 33.33%;
	padding-top: 0.35rem;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	float: left;
}

.social-media-section {
	display: block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #f1f1f1;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.15);
}

.social-media-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.social-media-section.no-social-media {
	display: none;
}

.social-image {
	width: auto;
	height: auto;
	padding: 5px;
}

.social-image.twitter {
	margin-top: 3px;
	padding: 5px;
}

.social-image.facebook {
	padding: 5px;
}

.social-image.linkedin {
	padding: 5px;
}

.social-image-link {
	width: 55px;
	height: 55px;
	padding: 8px;
	border-style: solid;
	border-width: 2px;
	border-color: hsla(180, 2%, 63%, 0.29);
	border-radius: 30px;
	background-color: #fff;
	-webkit-transition-duration: 300ms;
	transition-duration: 300ms;
}

.social-image-link:hover {
	border-color: #9ea1a1;
	background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.5));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.5));
}

.donate-button {
	width: 30px;
	height: 30px;
	background-image: url('/20160710165515/assets/images/circle-arrow-02.png');
	background-position: 0px 0px;
	background-size: cover;
	background-repeat: repeat-y;
}

.body {
	background-color: #beddeb;
	background-image: none;
	background-position: 0px 0px;
}

.paragraph {
	display: none;
}

.footer-paragraph {
	color: #cccdce;
	display: block;
}

.footer-paragraph.phone-numbers {
	display: none;
}

.email-us-paragraph {
	display: none;
}

.footer-container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 1.75rem 1rem;
}

.slide-caption-para {
	width: 100%;
	padding-right: 0.5rem;
	float: left;
	color: #151618;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-align: left;
	text-transform: none;
}

.slide-caption-para p:last-of-type {
	margin-bottom: 0;
}

.donate-link {
	display: block;
	width: 400px;
	margin-right: auto;
	margin-left: auto;
	text-decoration: none;
	background-color: transparent;
}

.share-icon {
	width: 20px;
	height: 20px;
}

.site-share-wrapper {
	position: relative;
}

.site-share-wrapper .nav-drop-list {
	right: 0;
	left: auto;
	width: 180px;
}

.link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.link-block a {
	background-color: transparent;
	cursor: pointer;
}

.link-block:hover {
	background-color: #0e3c9c;
	box-shadow: 0 -3px 0 0 #ba0c2f;
}

.link-block.new {
	display: none;
}

.select-cadet-dropdown {
	width: 125px;
	height: 34px;
	padding-top: 0.3rem;
	padding-right: 20px;
	padding-bottom: 0.3rem;
	border: 1px solid #fff;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	color: #777;
	float: left;
}

.connect-with-us {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 20px;
	padding-left: 12px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('/20160710165515/assets/images/blue-official-arrow-02.png');
	background-position: 0px 0px;
	background-size: cover;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #f2f3f4;
	font-weight: 600;
	text-transform: none;
}

.connect-with-us.mobile-portrait {
	padding-right: 45px;
	padding-left: 20px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-image: url('/20160710165515/assets/images/blue-official-arrow-02_1.png');
	background-position: 0px 0px;
	background-size: cover;
	color: #fff;
}

.bottom-footer-container {
	display: block;
	max-width: 1225px;
	margin-right: auto;
	margin-left: auto;
}

.inside-slider {
	height: 450px;
}

.inside-slideshow-link {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.inside-slider .w-slide {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.landing-page-title-wrapper {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	background-image: -webkit-linear-gradient(270deg, #ba0c2f, #ba0c2f);
	background-image: linear-gradient(180deg, #ba0c2f, #ba0c2f);
}

.h1-heading {
	margin-bottom: 0em;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #fff;
	font-size: 2rem;
	line-height: 1.375em;
	font-weight: 600;
	letter-spacing: 0.25px;
	text-transform: uppercase;
}

.right-sidebar {
	padding-right: 0.5px;
}

.h1 {
	margin-bottom: 0.25em;
	color: #005687;
}

.h2,
.main-content-wrap h2,
.left-sidebar-custom h2 {
	margin-top: 0em;
	margin-bottom: 0.5em;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.h3,
.main-content-wrap h3,
.left-sidebar-custom h3 {
	margin-top: 0.75em;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.h4,
.main-content-wrap h4,
.left-sidebar-custom h4 {
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.h5,
.main-content-wrap h5,
.left-sidebar-custom h5 {
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.h6,
.main-content-wrap h6,
.left-sidebar-custom h6 {
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
}

.slide-nav {
	font-size: 0.6rem;
}

.nav-dropdown-wrap {
	width: 100%;
}

.nav-dropdown-toggle {
	display: block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 5px 0px 6px;
	color: #fff;
}

.icon-design {
	margin-right: 0.5rem;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.75em;
}

.nav-dropdown-list-right {
	display: none;
	left: 180px;
	top: -8px;
	background-image: -webkit-linear-gradient(270deg, #1f2022, #1f2022);
	background-image: linear-gradient(180deg, #1f2022, #1f2022);
	left: 100%;
	background-image: -webkit-linear-gradient(270deg, #292d30, #292d30);
	background-image: linear-gradient(180deg, #292d30, #292d30);
}

@media (max-width: 1700px) {
	.last-main-nav-item .nav-drop-list .has-flyout .nav-dropdown-list-right {
		left: auto;
		right: 100%;
	}
}

.last-main-nav-item .nav-drop-list .has-flyout .nav-dropdown-list-right {
	width: 250px;
	min-width: 0;
}

.nav-dropdown-list-right.w--open {
	padding: 0.75rem 0.5rem;
	display: block;
}

.nav-dropdown-link {
	width: 100%;
	padding: 0.4rem 1.5rem 0.4rem 0.5rem;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
	font-weight: 600;
}

.last-main-nav-item .nav-drop-list .has-flyout .nav-dropdown-list-right .nav-dropdown-link {
	white-space: normal;
}

.nav-dropdown-link:hover {
	background-color: #9ea1a1;
	color: #000;
}

.list-item-2 {
	margin-bottom: 0px;
}

.current-breadcrumb {
	color: #001489;
	font-weight: 500;
}

.left-nav-divider {
	width: 269px;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.1);
}

.right-sidebar-column blockquote,
.main-content-wrap blockquote,
.left-sidebar-custom blockquote,
.blockquote {
	border-left-color: #ba0c2f;
	background-color: hsla(209, 9%, 48%, 0.1);
}

.news-caption-heading {
	margin-top: 1rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.2rem;
	font-weight: 600;
}

.news-caption-heading:hover {
	color: #ba0c2f;
}

.middle-footer-div {
	background-color: #373a3d;
	display: block;
}

.news-section {
	padding-top: 0;
	padding-bottom: 40px;
	background-color: #f1f1f1;
}

.cap-wrapper {
	display: block;
	width: 1250px;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0.75rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cap-wrapper._2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	max-width: 1300px;
	padding-top: 3rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.div-block-2 {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	text-align: left;
}

.main-content {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.caption-wrap {
	width: 50%;
}

.right-sidebar-column h1,
.main-content-wrap h1,
.left-sidebar-custom h1,
.heading-7 {
	color: #001489;
	display: block;
}

.link-block-2 {
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
}

.content-box-section-new {
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.13), transparent 20%, transparent 99%), -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20160710165515/assets/images/content-box-straight.webp'), -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.13), transparent 20%, transparent 99%), linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20160710165515/assets/images/content-box-straight.webp'), linear-gradient(180deg, #fff, #fff);
	background-position: 0px 0px, 0px 0px, 50% 0px, 0px 0px;
	background-size: auto, auto, cover, auto;
	background-repeat: repeat, repeat, no-repeat, repeat;
}

.learn-more-heading {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 4rem;
	padding-bottom: 4rem;
	box-shadow: none;
	color: transparent;
	font-size: 3rem;
	text-align: center;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000008;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="new-nav-drop-list"] {
	height: 0px;
}

.clearBoth {
	clear: both;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.75rem;
	}

	.button.header-search-button {
		z-index: 1000;
		width: 5rem;
		height: 60px;
		min-height: auto;
		border-style: none;
		background-color: #82b3cf;
		font-size: 1.125rem;
	}

	.button.slider-button {
		width: auto;
		float: none;
		border-style: none;
		background-color: #ab1b20;
		color: #f2f3f4;
	}

	.container {
		max-width: 100%;
	}

	.nav-section {
		padding-top: 0px;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 60px;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: flex-end;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		background-color: #001489;
		background-image: none;
		color: #fff;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1em 1rem;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		line-height: 1.375rem;
		text-align: left;
	}

	.nav-link:hover {
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.nav-link.w--current {
		display: block;
		height: 58px;
		padding-top: 1em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #ba0c2f;
		color: #fff;
	}

	.nav-link.w--current:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.15);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-menu-wrap {
		display: block;
		margin-top: 60px;
		padding-top: 5px;
		padding-bottom: 100vh;
		border-left-style: none;
		border-left-color: #ba0c2f;
		background-color: #0e3c9c;
		background-image: none;
		text-align: left;
	}

	.logo {
		margin-left: 0.75em;
		font-size: 1.375rem;
	}

	.website-tagline {
		margin-left: 1rem;
		margin-bottom: 0.5rem;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column {
		width: 100%;
		margin-bottom: 0.5rem;
		padding-top: 10px;
		padding-right: 0px;
		padding-bottom: 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.logo-image {
		width: auto;
		height: 4.5rem;
	}

	.menu-button {
		position: absolute;
		left: 0px;
		top: 0px;
		bottom: 0px;
		height: 60px;
		padding: 0px 1.5em 0px 1em;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
		color: rgba(0, 0, 0, 0.4);
		font-size: 1em;
		line-height: 3.5rem;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		background-color: rgba(0, 4, 53, 0.85);
	}

	.menu-button.w--open {
		background-color: transparent;
		text-transform: uppercase;
	}

	.footer-signoff-section {
		padding-top: 1rem;
		padding-bottom: 2rem;
		text-align: center;
	}

	.copyright-text {
		margin-bottom: 1rem;
		margin-left: 0.75rem;
	}

	.footer-signoff-grip {
		margin-top: 0rem;
		float: none;
	}

	.footer-column-wrap {
		max-width: 57%;
	}

	.footer-column-wrap._1 {
		max-width: 28%;
	}

	.footer-column-wrap._2 {
		width: 280px;
	}

	.header-content-right-wrap {
		margin-right: 0.75em;
		padding-bottom: 0.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
	}

	.website-logo {
		max-height: 100px;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: justify;
		justify-content: flex-end;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.header-menu-wrap {
		width: 100%;
		height: 60px;
	}

	.form-field.header-search-field {
		display: block;
		width: 100%;
		height: 60px;
		padding-right: 0rem;
		padding-left: 1.5rem;
		float: none;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		border-bottom-style: solid;
		border-bottom-width: 3px;
		background-color: #005687;
		color: #c7c7c7;
		font-size: 0.9375rem;
		line-height: 1.25em;
	}

	.form-field.header-search-field:focus {
		width: 100%;
		border-bottom: 3px solid #beddeb;
		background-color: #fff;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0em;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		border-left: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.main-content-column {
		width: 100%;
	}

	.main-content-column.right-sidebar-page {
		width: auto;
	}

	.inside-row-nested {
		padding-bottom: 0rem;
	}

	.right-sidebar-column {
		margin-top: 0em;
		padding-right: 2rem;
		padding-bottom: 2rem;
		padding-left: 2rem;
		border-top: 3px solid #9ea1a1;
		background-color: #f1f1f1;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.right-sidebar-column .box {
		margin-bottom: 2em;
		margin-left: 0em;
		padding-top: 1em;
	}

	.secondary-nav-icon {
		position: relative;
		z-index: 2;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.slider {
		height: 500px;
	}

	.slider-arrow {
		bottom: -380px;
		width: 42px;
		font-size: 1.75rem;
	}

	.slider-arrow.entry {
		bottom: -380px;
	}

	.slider-indicator {
		height: 3rem;
		padding-top: 0.5rem;
		color: #ab1b20;
	}

	.slider-image {
		background-image: -webkit-linear-gradient(111deg, transparent 36%, #fff 80%), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-2-new.png');
		background-image: linear-gradient(339deg, transparent 36%, #fff 80%), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-2-new.png');
		background-position: 0px 0px, 0px 0px, 100% 0px;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-image.slide-2 {
		height: 505px;
		background-image: -webkit-linear-gradient(103deg, transparent 28%, #fff), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.25)), url('/20160710165515/assets/images/slide-1.png');
		background-image: linear-gradient(347deg, transparent 28%, #fff), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.25)), url('/20160710165515/assets/images/slide-1.png');
		background-position: 0px 0px, 0px 0px, 100% 122%;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-image.slide-3 {
		background-image: -webkit-linear-gradient(111deg, transparent 33%, #fff 80%), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-3.jpg');
		background-image: linear-gradient(339deg, transparent 33%, #fff 80%), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-3.jpg');
		background-position: 0px 0px, 0px 0px, 50% 50%;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-caption-wrap {
		top: 89%;
		width: 100%;
		max-height: 250px;
		min-height: 200px;
		padding: 1rem 3.5rem 5rem;
		background-image: -webkit-linear-gradient(270deg, #beddeb, #beddeb);
		background-image: linear-gradient(180deg, #beddeb, #beddeb);
		box-shadow: 0 0 33px 0 #5b636b;
	}

	.inside-page-header-content-wrap {
		padding-top: 0.25rem;
		background-image: -webkit-linear-gradient(270deg, #f1f1f1, #f1f1f1);
		background-image: linear-gradient(180deg, #f1f1f1, #f1f1f1);
	}

	.inside-page-header-content-wrap.entry {
		padding-top: 0.25rem;
		background-image: -webkit-linear-gradient(270deg, #f1f1f1, #f1f1f1);
		background-image: linear-gradient(180deg, #f1f1f1, #f1f1f1);
	}

	.inside-page-header-section {
		position: relative;
		display: block;
		width: 100%;
		height: 120px;
		min-height: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
		opacity: 1;
	}

	.inside-content-beckground {
		max-width: 100%;
		margin-top: 0px;
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.main-content-wrap.nested-content-wrap {
		width: 100%;
		margin-right: 0em;
		padding-top: 1.5rem;
		padding-right: 2rem;
		padding-bottom: 1.5rem;
	}

	.main-content-wrap.nested-content-wrap.full {
		padding: 1.5rem 2rem;
	}

	.menu-button-icon {
		height: 60px;
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		color: #fff;
		font-size: 2rem;
		line-height: 4rem;
	}

	.menu-button-text {
		height: 60px;
		margin-left: 0.125rem;
		float: left;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75rem;
		line-height: 4rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		display: block;
		height: 60px;
		max-width: 100%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.intro_text_style,
	.intro-text-style {
		margin-top: 0.5rem;
	}

	.news-content-section {
		padding-bottom: 1rem;
	}

	.slider-caption-title {
		width: 100%;
		font-size: 1.4em;
		line-height: 1.8rem;
	}

	.footer-social-icon-link {
		margin-right: 15px;
		margin-bottom: 15px;
	}

	.footer-social-icon-image {
		width: 32px;
		height: 32px;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.md-doctor-form-reveal-wrap {
		display: none;
	}

	.table-style-embed {
		margin-bottom: 1rem;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/20160710165515/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 0.875rem;
		line-height: 1.875rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #28313b;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('/20160710165515/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.two-part-drop-list-section {
		padding: 2em 1rem 1em;
		border-bottom: 1px solid #fff;
		color: hsla(0, 0%, 100%, 0.5);
		font-size: 0.875rem;
		line-height: 1.25em;
		font-weight: 600;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.header-content-right-row {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.secondary-header-item-cta-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 1rem;
		margin-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.8125rem;
	}

	.header-search-wrap {
		margin-left: 0rem;
		padding: 0rem;
	}

	.header-search-form {
		display: block;
		width: 100%;
		min-width: 768px;
		background-color: #fff;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
		width: 100%;
	}

	.footer-social-icons {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.mobile-search-close-icon {
		position: relative;
		z-index: 2;
		display: none;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		padding: 1px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.mobile-search-active-bknd {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		z-index: 1;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.1);
	}

	.find-your-squadron-section {
		background-image: none;
	}

	.squadron-container {
		width: 250px;
		margin-right: 0px;
	}

	.squadron-container.zipcode {
		width: 350px;
		margin-left: 0px;
		padding-top: 20px;
		padding-bottom: 10px;
	}

	.i-am-a-text {
		padding-right: 5px;
		padding-left: 5px;
	}

	.go-button {
		width: 36px;
		height: 34px;
		margin-left: -3px;
		padding-left: 0.45rem;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

	.zip-text {
		padding-right: 7px;
		padding-left: 0px;
		float: left;
	}

	.zipcode-form-block {
		float: left;
	}

	.zip-code-text-field {
		margin-left: 5px;
		float: left;
	}

	.news-image {
		height: auto;
	}

	.donate-buttons-section {
		padding-top: 40px;
		box-shadow: inset 0 1px 14px 1px hsla(0, 0%, 56%, 0.5);
	}

	.donate-button-container {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.content-box-section {
		top: 300px;
		margin-top: 3rem;
		background-image: -webkit-linear-gradient(270deg, transparent, hsla(180, 2%, 61%, 0.26) 26%, hsla(180, 2%, 63%, 0.27) 27%, #9ea2a2);
		background-image: linear-gradient(180deg, transparent, hsla(180, 2%, 61%, 0.26) 26%, hsla(180, 2%, 63%, 0.27) 27%, #9ea2a2);
	}

	.cb {
		width: 50%;
	}

	.cb.left {
		padding-left: 1.5rem;
	}

	.cb.right {
		padding-right: 1rem;
		padding-left: 1.5rem;
	}

	.cb-wrapper {
		padding-top: 2rem;
		background-image: -webkit-linear-gradient(270deg, transparent, #9ea2a2 14%, #9ea2a2);
		background-image: linear-gradient(180deg, transparent, #9ea2a2 14%, #9ea2a2);
	}

	.cb-content {
		padding-left: 1.5rem;
	}

	.cb-text-heading {
		font-size: 1.6rem;
		line-height: 1.9rem;
	}

	.column-wrapper {
		padding-top: 0rem;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.social-image.twitter {
		margin-top: 3px;
	}

	.slide-caption-para {
		width: 100%;
		font-size: 0.9rem;
		line-height: 1.4rem;
	}

	.link-block {
		display: block;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 15px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.link-block.new {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 5rem;
		margin-left: auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		background-color: transparent;
		background-image: none;
	}

	.select-cadet-dropdown {
		padding-left: 7px;
	}

	.connect-with-us.mobile-portrait {
		padding-right: 40px;
		padding-left: 25px;
	}

	.inside-slider {
		height: 505px;
	}

	.landing-page-title-wrapper {
		padding-left: 2rem;
		background-color: #ba0c2f;
		background-image: none;
	}

	.h1-heading {
		margin-bottom: 0em;
		font-size: 1.9rem;
	}

	.right-sidebar {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.h2 {
		font-size: 1.65rem;
	}

	.h3 {
		font-size: 1.4rem;
	}

	.h4 {
		font-size: 1.15rem;
	}

	.header-search-field {
		height: 60px;
		margin-bottom: 0px;
		padding: 0.75em 1em;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		font-size: 1.0625rem;
		border-radius: 0;
	}

	.mobile-search-go-button {
		width: 20%;
		height: 60px;
		padding: 0px;
		background-color: #0e3c9c;
		background-image: url('/20160710165515/assets/images/circle-arrow-02_1.png');
		background-position: 50% 50%;
		background-size: 35px 35px;
		background-repeat: no-repeat;
		color: transparent;
		border: 0 none;
		border-radius: 0;
	}

	.mobile-search-button {
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		width: 5rem;
		height: 60px;
		background-image: url('/20160710165515/assets/images/search.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		background-color: transparent;
	}

	.mobile-search-button:hover {
		background-color: rgba(0, 4, 53, 0.85);
	}

	.mobile-search-dd-wrap {
		position: relative;
		display: none;
		height: 0px;
		margin-top: 60px;
	}

	.mobile-search-form-block {
		margin-bottom: 0px;
		display: none;
	}

	.mobile-search-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.mobile-search-form fieldset {
		display: flex;
		margin: 0px;
		padding: 0px;
		width: 100%;
	}

	.mobile-search-form fieldset div {
		display: flex;
	}

	.mobile-search-field {
		width: 80%;
		height: 60px;
		margin-bottom: 0px;
		font-size: 1.1rem;
	}

	.mobile-share-wrapper {
		position: relative;
	}

	.news-section {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.cap-wrapper._2 {
		width: 100%;
		padding-top: 2rem;
		padding-right: 2rem;
	}

	.caption-wrap {
		width: 100%;
		max-width: 600px;
	}

	.content-box-section-new {
		height: auto;
		background-image: url('/20160710165515/assets/images/content-box-straight.webp');
		background-position: 0px 0px;
		background-size: contain;
	}

	.learn-more-heading {
		padding-top: 4.4rem;
		padding-bottom: 4.4rem;
		box-shadow: none;
		font-size: 2rem;
	}

	.inside-content-beckground.no-columns .main-content-wrap.nested-content-wrap {
		padding: 1.5rem 2rem;
	}

	.right-sidebar-column .box.tools input[type="text"] {
		width: 100%;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 1.6875rem;
		line-height: 1.3125em;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 1.4375em;
	}

	h3 {
		font-size: 1.3125rem;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}

	.button.slider-button {
		box-shadow: none;
	}

	.logo {
		padding-left: 0px;
	}

	.row.footer-row {
		margin-right: 10px;
		margin-left: 10px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column {
		padding-top: 10px;
	}

	.logo-image {
		width: auto;
		height: 3rem;
	}

	.website-tagline {
		margin-left: 0.5rem;
	}

	.website-logo {
		max-height: 80px;
	}

	.footer-column-wrap {
		max-width: 100%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.footer-column-wrap._1 {
		max-width: 100%;
	}

	.footer-column-wrap._2 {
		max-width: 100%;
	}

	.footer-column-wrap._3 {
		max-width: 100%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.right-sidebar-column .box {
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0em;
	}

	.secondary-nav-icon {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.slider {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slider-mask {
		width: 100%;
		height: auto;
	}

	.slider-arrow {
		bottom: -75%;
		height: 50px;
	}

	.slider-arrow.right {
		bottom: -75%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 50px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.slider-arrow.right.entry {
		bottom: -75%;
	}

	.slider-arrow.entry {
		bottom: -75%;
	}

	.slider-indicator {
		top: 465px;
		bottom: auto;
		margin-top: -2rem;
		font-size: 0.5rem;
	}

	.main-section {
		box-shadow: 0 0 6px 1px #9ea2a2;
	}

	.slider-image {
		position: relative;
		height: 470px;
		background-color: #fff;
		background-image: -webkit-linear-gradient(111deg, transparent 36%, #fff 80%), -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-2-new.png');
		background-image: linear-gradient(339deg, transparent 36%, #fff 80%), linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-2-new.png');
		background-position: 0px 0px, 0px 0px, 100% 50%;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-image.slide-2 {
		height: 470px;
		background-image: -webkit-linear-gradient(101deg, transparent 43%, #fff), -webkit-linear-gradient(270deg, transparent 14%, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-1.png');
		background-image: linear-gradient(349deg, transparent 43%, #fff), linear-gradient(180deg, transparent 14%, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-1.png');
		background-position: 0px 0px, 0px 0px, 100% 0px;
		background-size: auto, auto, cover;
		background-repeat: repeat, repeat, no-repeat;
	}

	.slider-caption-wrap {
		top: 100%;
		width: 100%;
		height: 100%;
		max-height: 500px;
		margin-right: 0%;
		margin-left: 0%;
		padding: 1rem 4%;
		background-image: -webkit-linear-gradient(270deg, #beddeb, #beddeb);
		background-image: linear-gradient(180deg, #beddeb, #beddeb);
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.inside-page-header-section {
		min-height: 80px;
	}

	.main-content-wrap.nested-content-wrap.full {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.intro_text_style,
	.intro-text-style {
		margin-top: 0.5rem;
		font-size: 1.1em;
		line-height: 1.45em;
	}

	.slider-caption-title {
		margin-bottom: 0.25rem;
		font-size: 1.2em;
		line-height: 1.1em;
	}

	.header-container {
		padding-bottom: 10px;
	}

	.header-content-right-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around;
	}

	.secondary-header-item-cta-link {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		padding-top: 0.75em;
		padding-right: 2.5em;
		padding-left: 1.5em;
		background-position: 90% 50%;
		background-size: 8px 8px;
		font-size: 0.6875rem;
		line-height: 1rem;
	}

	.header-search-form {
		min-width: auto;
	}

	.special-notice-container {
		padding: 10px;
	}

	.special-notice-title {
		font-size: 0.9rem;
		line-height: 1rem;
		text-align: right;
	}

	.special-notice-text {
		font-size: 0.7rem;
		line-height: 1rem;
	}

	.find-your-squadron-section {
		padding-top: 6px;
		padding-bottom: 20px;
		text-align: left;
	}

	.squadron-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 220px;
		margin-right: auto;
		margin-left: auto;
		padding-right: 5px;
		padding-left: 15px;
		float: none;
	}

	.squadron-container.zipcode {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		margin-right: auto;
		margin-left: auto;
		padding-top: 15px;
		padding-right: 5px;
		padding-left: 5px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.i-am-a-text {
		padding-right: 5px;
		padding-bottom: 0.5rem;
		padding-left: 5px;
		font-size: 1rem;
	}

	.go-button {
		height: 35px;
		margin-right: 2px;
		margin-left: 0px;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		font-size: 0.78rem;
		text-align: center;
	}

	.zip-text {
		width: 125px;
		height: 34px;
		margin-right: 0px;
		padding-right: 4px;
		padding-left: 5px;
		font-size: 0.75rem;
	}

	.donate-button-container {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.content-box-section {
		top: 225px;
		margin-top: 2.5rem;
	}

	.cb {
		width: 100%;
		padding: 0.75rem;
	}

	.cb.left {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.cb.right {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.cb-wrapper {
		padding-top: 0rem;
		background-image: -webkit-linear-gradient(270deg, transparent, #b8b8b8 1%, #9ea2a2);
		background-image: linear-gradient(180deg, transparent, #b8b8b8 1%, #9ea2a2);
	}

	.cb-content {
		padding-left: 2.5rem;
	}

	.cb-content:hover {
		-webkit-transform: scale(1.03);
		-ms-transform: scale(1.03);
		transform: scale(1.03);
	}

	.cb-image {
		border-radius: 0px;
	}

	.cb-text-heading {
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 0rem;
		font-size: 2.2rem;
		line-height: 2.7rem;
	}

	.column-wrapper {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.social-media-container {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.social-image.twitter {
		margin-top: 2px;
	}

	.social-image-link {
		width: 40px;
		height: 40px;
		min-height: 25px;
		min-width: 25px;
		padding: 4px;
		border-radius: 34px;
	}

	.footer-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.slide-caption-para {
		font-size: 0.83rem;
		line-height: 1.25rem;
	}

	.select-cadet-dropdown {
		width: 125px;
		margin-bottom: 0px;
		padding-right: 8px;
		padding-left: 8px;
		font-size: 0.75rem;
	}

	.connect-with-us {
		width: 170px;
		height: 48px;
		padding-right: 15px;
		padding-left: 9px;
		background-image: url('/20160710165515/assets/images/blue-official-arrow-02.png');
		background-position: 0px 0px;
		background-size: contain;
		background-repeat: no-repeat;
		font-size: 0.9rem;
	}

	.connect-with-us.mobile-portrait {
		width: 150px;
		margin-right: 5px;
		padding-right: 15px;
		padding-left: 15px;
		background-image: url('/20160710165515/assets/images/blue-official-arrow-02_2.png');
		background-position: 100% 50%;
		background-size: cover;
		font-size: 0.85rem;
	}

	.inside-slider {
		height: 470px;
	}

	.blockquote {
		font-size: 1.05em;
		line-height: 1.45em;
	}

	.paragraph-text {
		font-size: 0.95rem;
		line-height: 1.45em;
	}

	.list-text {
		font-size: 0.95rem;
		line-height: 1.45em;
	}

	.cap-wrapper._2 {
		width: 90%;
	}

	.caption-wrap {
		max-width: 500px;
	}

	.content-box-section-new {
		height: auto;
		background-image: -webkit-linear-gradient(270deg, transparent, #a5a4a4 60%, #a5a4a4 82%, #a5a5a5 84%, #9ea2a2), url('/20160710165515/assets/images/content-box-straight.webp'), -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, transparent, #a5a4a4 60%, #a5a4a4 82%, #a5a5a5 84%, #9ea2a2), url('/20160710165515/assets/images/content-box-straight.webp'), linear-gradient(180deg, #fff, #fff);
		background-position: 0px 0px, 0px 0px, 0px 0px;
		background-size: auto, contain, auto;
		background-repeat: repeat, no-repeat, repeat;
	}

	.learn-more-heading {
		margin-bottom: 0em;
	}

	.inside-content-beckground.right-column .right-sidebar-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-content-beckground.no-columns .main-content-wrap.nested-content-wrap {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

@media (max-width: 479px) {
	body {
		font-size: 0.9375rem;
		line-height: 1.5em;
	}

	h1 {
		font-size: 1.625rem;
	}

	h2 {
		font-size: 1.4375rem;
		line-height: 1.5em;
	}

	.button.header-search-button {
		width: 5rem;
		border-style: none;
	}

	.button.slider-button {
		margin-bottom: 1rem;
		float: left;
		font-size: 0.75rem;
	}

	.nav-link {
		font-size: 1rem;
	}

	.nav-link.two-part-mobile {
		width: 75%;
	}

	.logo {
		margin-right: auto;
		margin-left: auto;
	}

	.row.footer-row {
		margin-right: 1rem;
		margin-left: 1rem;
	}

	.logo-image {
		height: 3.5rem;
	}

	.menu-button {
		width: auto;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.footer-column-wrap {
		padding-right: 0rem;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.footer-column-wrap._1 {
		padding-left: 0rem;
	}

	.footer-column-wrap._2 {
		padding-left: 0rem;
	}

	.footer-column-wrap._3 {
		padding-left: 0rem;
	}

	.header-content-right-wrap {
		display: none;
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.form-field.header-search-field {
		padding-right: 28%;
	}

	.secondary-nav-list {
		max-width: 75%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.slider-section {
		width: 100%;
		max-width: 479px;
		min-width: 320px;
	}

	.slider {
		width: 100%;
		max-width: none;
	}

	.slider-mask {
		width: 100%;
		height: auto;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
	}

	.slider-arrow {
		bottom: 30%;
		width: 38px;
		height: 48px;
		max-width: 50px;
		font-size: 1.25rem;
	}

	.slider-arrow.right {
		bottom: 30%;
		width: 38px;
		height: 48px;
	}

	.slider-arrow.right.entry {
		bottom: -50%;
	}

	.slider-arrow.entry {
		bottom: -50%;
	}

	.slider-indicator {
		top: 200px;
	}

	.slider-slide {
		width: 100%;
	}

	.slider-image {
		height: 200px;
		background-image: -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-2-new.png');
		background-image: linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-2-new.png');
		background-position: 0px 0px, 100% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.slider-image.slide-2 {
		position: relative;
		height: 200px;
		background-image: url('../images/slide-1.png');
		background-position: 100% 0px;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slider-image.slide-3 {
		background-image: -webkit-linear-gradient(270deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-3.jpg');
		background-image: linear-gradient(180deg, transparent, rgba(5, 15, 36, 0.5)), url('/20160710165515/assets/images/slide-3.jpg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.slider-caption-wrap {
		display: block;
		width: 100%;
		height: 100%;
		min-width: 320px;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1rem;
		padding-bottom: 0rem;
		padding-left: 1rem;
		background-image: -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, #fff, #fff);
		box-shadow: none;
		top: 0;
	}

	.inside-page-header-content-wrap {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.main-content-wrap.nested-content-wrap {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-wrap.nested-content-wrap.full {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.menu-button-icon {
		display: block;
		float: left;
	}

	.menu-button-text {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.intro_text_style,
	.intro-text-style {
		font-size: 1.025em;
		line-height: 1.4em;
	}

	.slider-caption-title {
		margin-bottom: 0.35rem;
		font-size: 1.3em;
		line-height: 1.55rem;
	}

	.two-part-button-toggle {
		width: 25%;
		line-height: 1.625rem;
	}

	.header-container {
		padding-bottom: 0px;
	}

	.special-notice-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.special-notice-title {
		padding-bottom: 6px;
		font-size: 1.1rem;
		line-height: 0.9rem;
		letter-spacing: -0.9px;
	}

	.special-notice-text {
		width: 100%;
		min-width: 300px;
		font-size: 0.8rem;
		line-height: 0.9rem;
		text-align: center;
	}

	.find-your-squadron-section {
		display: block;
		margin-bottom: 0px;
		padding-bottom: 2.5rem;
		background-image: none;
		text-align: center;
	}

	.squadron-container {
		display: inline-block;
		width: 240px;
		margin-top: 10px;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.squadron-container.zipcode {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 15px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}

	.i-am-a-text {
		padding-bottom: 0.65rem;
		float: none;
		text-align: center;
	}

	.go-button {
		width: 36px;
		height: 35px;
	}

	.zip-text {
		width: 150px;
		height: 34px;
	}

	.zipcode-form-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.donate-buttons-section {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.donate-button-container {
		width: 90%;
	}

	.donate-text {
		margin-right: 5px;
		font-size: 0.75rem;
	}

	.content-box-section {
		top: 150px;
		margin-top: 0rem;
		background-image: -webkit-linear-gradient(270deg, transparent 10%, hsla(180, 2%, 61%, 0.26) 37%, hsla(180, 2%, 63%, 0.27) 38%, #9ea2a2);
		background-image: linear-gradient(180deg, transparent 10%, hsla(180, 2%, 61%, 0.26) 37%, hsla(180, 2%, 63%, 0.27) 38%, #9ea2a2);
	}

	.cb-wrapper {
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-bottom: 1rem;
	}

	.cb-content {
		padding-left: 1.5rem;
	}

	.cb-content._2 {
		padding-left: 1.5rem;
	}

	.cb-image {
		width: 50%;
	}

	.cb-text-heading {
		width: 50%;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 0rem;
		font-size: 1.55rem;
		line-height: 1.8rem;
	}

	.column-wrapper {
		width: 100%;
	}

	.social-media-section {
		padding-top: 0rem;
	}

	.social-media-container {
		width: 93%;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.social-image {
		padding: 0px;
	}

	.social-image.twitter {
		margin-top: 0px;
		padding: 0px;
	}

	.social-image.facebook {
		padding: 0px;
	}

	.social-image.linkedin {
		padding: 1px 0px 0px 1px;
	}

	.social-image-link {
		width: 35px;
		height: 35px;
		padding: 3px 6px 6px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-color: transparent;
		border-radius: 20px;
	}

	.donate-button {
		width: 25px;
		height: 25px;
	}

	.footer-container {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.donate-link {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.link-block.new {
		left: 40%;
	}

	.select-cadet-dropdown {
		width: 150px;
	}

	.connect-with-us {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: auto;
		margin-right: auto;
		margin-bottom: 14px;
		margin-left: auto;
		padding-top: 6px;
		padding-bottom: 6px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-image: -webkit-linear-gradient(270deg, #001489, #001489);
		background-image: linear-gradient(180deg, #001489, #001489);
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
		text-align: center;
	}

	.connect-with-us.mobile-portrait {
		display: none;
	}

	.inside-slider {
		height: 200px;
	}

	.landing-page-title-wrapper {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.h1-heading {
		font-size: 1.8rem;
		line-height: 1.175em;
	}

	.h2 {
		font-size: 1.55rem;
		line-height: 1.3em;
	}

	.h3 {
		font-size: 1.3rem;
		line-height: 1.3em;
	}

	.h4 {
		font-size: 1.1rem;
	}

	.blockquote {
		font-size: 1.025em;
		line-height: 1.4em;
	}

	.paragraph-text {
		font-size: 0.9rem;
		line-height: 1.4em;
	}

	.list-text {
		font-size: 0.9rem;
		line-height: 1.4em;
	}

	.news-section {
		padding-bottom: 0px;
	}

	.cap-wrapper {
		width: auto;
		padding-top: 0rem;
	}

	.content-box-section-new {
		background-image: -webkit-linear-gradient(270deg, transparent, #a5a4a4 25%, #a5a5a5 54%, #a4a5a5 65%, #9ea2a2), url('/20160710165515/assets/images/content-box-straight.webp'), -webkit-linear-gradient(270deg, #fff, #fff);
		background-image: linear-gradient(180deg, transparent, #a5a4a4 25%, #a5a5a5 54%, #a4a5a5 65%, #9ea2a2), url('/20160710165515/assets/images/content-box-straight.webp'), linear-gradient(180deg, #fff, #fff);
		background-position: 0px 0px, 0px 0px, 0px 0px;
		background-size: auto, contain, auto;
		background-repeat: repeat, no-repeat, repeat;
	}

	.learn-more-heading {
		margin-bottom: 0em;
		padding-top: 3.3rem;
		padding-bottom: 3.3rem;
		font-size: 3rem;
	}


	.inside-content-beckground.no-columns .main-content-wrap.nested-content-wrap {
		padding-right: 1rem;
		padding-left: 1rem;
	}


	.website-tagline {
		margin-left: 0.7rem;
	}
}

@media (max-width: 353px) {
	li.footer-signoff-list-item:first-of-type {
		margin-bottom: 1rem;
	}
}

/******************** Webflow **********************/
/***************************************************/
html {
	font-size: 1rem;
}

/* Set font render */
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

/* Superscript */
sup {
	font-size: xx-small;
}

/* Clear input field styling */
input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/* --- Style Nav Slider Dots --- */
/* .w-num .w-round or omit for square*/
.w-slider-dot.w-active {
	background-color: #ba0c2f !important;
	position: relative;
}

/* Set divider style */
hr {
	color: #ab1b20;
	border-color: #ab1b20;
	border: 0px;
	border-top: 1px solid;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
}

/* Footer phone number auto-styling on mobile devices */
.footer-section a[href^=tel] {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: inherit;
	background-color: transparent;
}

/* Adjust heading styles when they're set as links */
xh1 > a {
	color: inherit;
}

xh1 > a:hover {
	color: inherit;
}

/* Text selection style for Mozilla based browsers */
::-moz-selection {
	background: #001489;
	color: #fff;
}

/* Text selection style for Safari browser */
::selection {
	background: #001489;
	color: #fff;
}

/* Change Color of Form Placeholder Text */
input.input-box,
textarea {
	background: white;
	color: black;
}

.w-input::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #777;
	opacity: 1;
}

.w-input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #777;
	opacity: 1;
}

.w-input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #777;
	opacity: 1;
}

.w-input:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #777;
	opacity: 1;
}

x.w-slider-nav > div {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div:hover {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div.w-active {
	border: white 3px solid;
	background: white;
}

x.w-slider-nav > div.w-active:hover {
	border: white 3px solid;
	background: white;
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints **********************/
/*****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 992px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 768px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter,
#printHeader {
	display: none;
	visibility: hidden;
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
		max-width: none;
		min-width: 0;
	}
}

.mobile-navigation-menu {
	background: #0e3c9c;
}

html.mm-wrapper_opening .mm-slideout {
	transform: translate(80%, 0px) !important;
	z-index: 0;
}

.mobile-navigation-menu li .nav-link {
	margin-bottom: 1px;
	padding: 1em 1rem;
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	color: #fff;
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 1.375rem;
	text-align: left;
	text-transform: uppercase;
	background-color: transparent;
}

.mobile-navigation-menu li .nav-link:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

.mobile-navigation-menu .mm-btn_next + .nav-link {
	width: 75%;
	margin-right: 25%;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 25%;
	box-shadow: none;
	margin-bottom: -1px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25) !important;
	background-color: rgba(0, 0, 0, 0.15);
}

.mm-listitem .mm-btn_next:before {
	border-left-color: transparent;
	border-left: 1px solid hsla(0, 0%, 100%, 0.25) !important;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: hsla(0, 0%, 100%, 0.5);
	right: 45%;
	height: 9px;
	width: 9px;
}

.mm-menu .mm-listview .mm-btn_next:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

.mobile-navigation-menu li.active .nav-link {
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #ba0c2f;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.25);
}

.mm-navbar {
	background-color: rgba(0, 0, 0, 0.15);
	height: 58px;
	box-shadow: none;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25) !important;
	margin-left: 0;
	margin-right: 0;
	color: #fff;
	text-align: left;
	padding: 0 30px;
}

.mm-navbar a {
	background-color: transparent;
	cursor: pointer;
}

.mm-navbar .mm-navbar__title {
	color: #fff !important;
	padding: 1em 0;
	font-size: 1.125rem;
	line-height: 1.375rem;
	text-align: left;
	font-weight: 400;
	text-transform: uppercase;
	background-color: transparent;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: hsla(0, 0%, 100%, 0.5);
	left: 17px;
	top: 17px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: 0px;
}

/******************* Skip WCAG Button  ***************/
/*****************************************************/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0 !important;
	border: 0px !important;
	line-height: 0;
	transition: none;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.5em 1.5em !important;
	line-height: 1.1rem;
	z-index: 5000;
}

/************** Mobile Frienldy Image  ***************/
/*****************************************************/
@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/****************** Accesible Menu  *****************/
/*****************************************************/
@media (max-width: 991px) {
	.desktop-navigation-menu {
		display: none;
	}
}

@media (min-width: 992px) {
	.nav-container {
		background: transparent;
	}

	.desktop-navigation-menu {
		display: flex;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.desktop-navigation-menu ul.main-ul {
		display: flex;
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		width: 100%;
		align-items: center;
		height: 50px;
	}

	.desktop-navigation-menu ul.main-ul li.main-li {
		flex: 1;
		height: 50px;
		align-items: center;
		position: relative;
		margin: 0px;
		padding: 0px;
		border-right: 1px solid rgba(20, 25, 82, 0.85);
	}

	.desktop-navigation-menu ul.main-ul li.main-li:first-child {
		border-left: 1px solid rgba(20, 25, 82, 0.85);
	}

	.desktop-navigation-menu ul.main-ul li.main-li a.main-nav-link {
		height: 50px;
		text-align: center;
		width: 100%;
		display: inline-block;
		padding: 0.85rem 3rem 0.85rem 1rem;
		color: #fff;
		font-size: 1.125rem;
		line-height: 1.375em;
		font-weight: 400;
		text-align: center;
		text-transform: uppercase;
		text-decoration: none;
		background-color: transparent;
	}

	.desktop-navigation-menu ul.main-ul li.main-li a.main-nav-link:hover,
	.desktop-navigation-menu ul.main-ul li.main-li a.main-nav-link.active {
		background-color: #0b358e;
		box-shadow: 0 -3px 0 0 #ba0c2f;
		color: #fff;
	}

	.desktop-navigation-menu ul.main-ul li.main-li.has-dropdown a.main-nav-link:after {
		content: "\e603";
		font-family: webflow-icons;
		font-style: normal;
		font-weight: 400;
		width: 1em;
		height: 1em;
		font-size: 0.75rem;
		color: #cecccc;
		position: absolute;
		right: 2rem;
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container {
		display: none;
		position: absolute;
		top: 100%;
		z-index: 100;
		left: 0;
		width: 100%;
		background-color: #33383b;
		color: #fff;
	}

	.desktop-navigation-menu ul.main-ul li.main-li.open .dropdown-container {
		display: block;
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container ul {
		margin: 0px;
		padding: 0px;
		list-style-type: none;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container li {
		margin: 0px;
		padding: 0px;
		position: relative;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container li a {
		display: block;
		padding: 0.5em 1.5em 0.5em 0.5rem;
		color: #cccdce;
		font-size: 0.875rem;
		font-weight: 600;
		text-decoration: none;
		background-color: transparent;
	}


	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container li a:hover {
		background-color: #9ea1a1;
		color: #000;
		font-weight: 600;
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container li.has-dropdown a {
		position: relative;
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container li.has-dropdown > a:after {
		margin-right: 0.7rem;
		-webkit-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75em;
		position: absolute;
		top: 1.2em;
		right: 0;
		bottom: 0;
		width: 1em;
		height: 1em;
		font-family: webflow-icons;
		content: "\e603";
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container li.has-dropdown .dropdown-container {
		position: absolute;
		top: -8px;
		background-image: -webkit-linear-gradient(270deg, #1f2022, #1f2022);
		background-image: linear-gradient(180deg, #1f2022, #1f2022);
		left: 100%;
		background-image: -webkit-linear-gradient(270deg, #292d30, #292d30);
		background-image: linear-gradient(180deg, #292d30, #292d30);
		padding: 0.75rem 0.5rem;
		width: 100%;
		display: none;
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container li.has-dropdown.open .dropdown-container {
		display: block;
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container li.has-dropdown .dropdown-container ul {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.desktop-navigation-menu ul.main-ul li.main-li .dropdown-container li.has-dropdown ul li {
		padding-left: 0px;
		padding-right: 0px;
	}
}
/****************** Inside Page Styles  ***************/
/*****************************************************/
/*messages*/
.success {
	display: block;
	background-color: #167c28;
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #ffffff;
}

.error {
	display: block;
	background-color: #ba0c2f;
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #ffffff;
}

.success p,
.success ul li,
.error p,
.error ul li {
	margin-bottom: 0;
	margin-top: 0;
	color: #ffffff;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 400;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(255, 255, 255, 0.75);
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: normal;
	text-transform: none;
	letter-spacing: 0.04em;
	color: white;
	background-color: #001489;
	border-bottom: 2px solid white;
	padding: 0.625em 0.625rem;
	text-align: left;
}

table.styled th {
	font-weight: normal;
	letter-spacing: 0.02em;
	padding: 0.5em 0.625rem;
	background-color: rgba(158, 162, 162, 0.75);
	border-bottom: 2px solid white;
	color: black;
	text-align: left;
}

table.styled thead th a {
	color: #fff;
	background-color: transparent;
	border-bottom-color: rgba(255, 255, 255, 0.3);
}

table.styled thead th a:hover {
	color: #fff;
	border-bottom-color: rgba(255, 255, 255, 1);
}

table.styled tbody th a {
	color: #000;
	background-color: transparent;
	border-bottom-color: rgba(0, 0, 0, 0.3);
}

table.styled tbody th a:hover {
	color: #000;
	border-bottom-color: #000;
}

table.styled td {
	padding: 0.375em 0.625rem;
}

table.styled.striped tr:nth-child(even) {
	background: rgba(0, 0, 0, 0.1);
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}


/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

.main-content-wrap td img {
	max-width: 100%;
}

@media (max-width: 767px) {

	.presentation-table td {
		display: inline-block !important;
		border-right: none !important;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
	font-size: 0.875em;
	line-height: 1.42857143;
	padding: 4px 10px;
	border: 1px solid #cccccc;
	color: #333333;
}

textarea {
	resize: vertical;
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: rgba(0, 0, 0, 0.15) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(0, 0, 0, 0.15);
	border: 0px;
	color: rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*links without border*/
a.no_border,
a.no-border {
	border: 0 none;
}

/*related documents*/
.box.documents .content > table {
	margin-bottom: 10px;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
}

/* ****************** Modules ********************* */
/***************************************************/
/* events */
#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_list div.events_module_calendar .fc-view-container table tr th,
#events_module.cms_list .cms_td_header,
#events_module.cms_list .fc-axis.fc-widget-content {
	background: #5c5c5c;
	color: #ffffff;
	font-family: inherit;
}

#events_module.cms_list div.events_module_calendar .fc-view-container table tr th span {
	background-color: #5c5c5c;
	color: #fff;
	font-family: inherit;
}

#events_module.cms_list .fc-daygrid .fc-daygrid-day-number {
	background-color: unset;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* news */
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
	}
}

/* parnters */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	background-color: #696969;
	font-family: inherit;
	color: #fff;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */

/* submissionns form */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}


#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}


#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* search */
.module-search input[type="search"] {
	padding: 8px 10px;
}

.module-search .collection.search-collection > .node.search-node {
	border-top: #cccccc solid 1px;
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.module-search .collection.search-collection > .node.search-node:first-child {
	border: 0px;
	margin-top: 0px;
	padding-top: 0px;
}

@media (max-width: 991px) {
	.module-search input[type="search"] {
		width: 100%;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 300px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #001489;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #001489;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
	font-family: 'Open Sans', sans-serif;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ff0000;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 767px) {
	#account_module.form .form_container .login_field {
		max-width: 100%;
	}

	#account_module.form .form_container input[type="text"],
	#account_module.form .form_container input[type="password"],
	#account_module.form .form_container select {
		width: 100%;
	}
}


/* *************** Content Boxes ****************** */
/***************************************************/
/* event */
#events_module.homepage_contentbox {
	padding: 15px;
}

#events_module.homepage_contentbox,
#events_module.homepage_contentbox a,
#events_module.homepage_contentbox a:hover {
	color: #000;
	text-align: left;
}

#events_module.homepage_contentbox td.date {
	width: 100px;
}


#events_module.homepage_contentbox td.title {
	width: calc(100% - 100px);
}


/* news */
#news_module.homepage_contentbox {
	padding: 15px;
}

#news_module.homepage_contentbox,
#news_module.homepage_contentbox a,
#news_module.homepage_contentbox a:hover {
	color: #000;
	text-align: left;
}

#news_module.homepage_contentbox .cms_title h3 {
	margin: 0px;
	padding: 0px;
	font-size: 1.1rem;
	line-height: 1.5rem;
}

#news_module.homepage_contentbox .cms_date h3 {
	margin: 0px;
	padding: 0px;
	font-size: 0.9rem;
	line-height: 1.2rem;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 15px;
}

/* partners */
#partners_module.homepage_contentbox {
	padding: 15px;
}

#partners_module.homepage_contentbox,
#partners_module.homepage_contentbox a,
#partners_module.homepage_contentbox a:hover {
	color: #000;
	text-align: left;
}


/* form */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	padding: 15px;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}


#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	color: inherit;
}

/* slideshow */
.slider.homepage-slideshow {
	display: block;
	width: 100%;
	height: auto;
}

.main-slidesshow {
	height: 27vw;
	background-color: #f1f1f1;
}

.main-slidesshow .w-slide {
	height: auto;
}

.main-slidesshow ~ .slider-indicator .w-slider-dot {
	background-color: #ccc;
}

.main-slidesshow ~ .slider-indicator .w-slider-dot.w-active {
	background-color: #ba0c2f !important;
}

.main-slidesshow ~ .slider-arrow {
	left: auto;
	top: auto;
	right: 1rem;
	bottom: 1.5rem;
	width: 3rem;
	height: 3rem;
	background-color: #ba0c2f;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	border-radius: 0px;
}

.main-slidesshow ~ .slider-arrow.w-slider-arrow-left {
	margin-right: 3.5rem;
}

.main-slidesshow ~ .slider-arrow .slider-arrow-icon {
	display: block;
	margin-right: auto;
	margin-left: auto;
	font-size: 1.5rem;
}

.main-slidesshow ~ .slider-arrow .w-icon-slider-left:before {
	content: "\e601";
}

.main-slidesshow ~ .slider-arrow .w-icon-slider-right:before {
	content: "\e600";
}

.main-slidesshow ~ .slider-indicator {
	padding-top: 0.75rem;
	font-size: 0.75rem;
	top: auto;
}

.main-slidesshow .slide-image {
	display: block;
	width: 70%;
	height: 27vw;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.main-slidesshow .slide-image.no-caption {
	width: 100%;
}

.main-slidesshow .slide-caption-wrapper {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	margin-right: auto;
	margin-left: auto;
	padding: 1rem 2% 4.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: rgba(241, 241, 241, 0.8);
}

.main-slidesshow .slide-caption-wrapper p,
.main-slidesshow .slide-caption-wrapper li,
.main-slidesshow .slide-caption-wrapper span {
	letter-spacing: 0.02em;
	color: #151618;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 700;
	text-align: left;
	display: inline-block;
	width: 100%;
	text-transform: none;
}

.main-slidesshow .slide-caption-header {
	width: 100%;
	margin-top: 0rem;
	font-family: Ubuntu, Helvetica, sans-serif;
	color: #001489;
	font-size: 1.6rem;
	line-height: 2.1rem;
	font-weight: 600;
	margin-bottom: 2px;
}

.main-slidesshow .slide .more-link {
	display: inline-block;
	background-color: #ba0c2f;
	box-shadow: none;
	color: #fff;
	margin-top: 1em;
	margin-bottom: 0.25em;
	font-size: 0.85rem;
	text-align: center;
	transition: all 300ms ease;
	padding: 0.5em 1em;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-decoration: none;
}

.main-slidesshow .slide .more-link:hover {
	background-color: #001489;
	color: #fff;
}

@media (max-width: 991px) {
	.main-slidesshow {
		height: 48vw;
	}

	.main-slidesshow ~ .slider-arrow {
		right: 1rem;
		bottom: 1rem;
		width: 2.25rem;
		height: 2.25rem;
	}

	.main-slidesshow ~ .slider-arrow.w-slider-arrow-left {
		margin-right: 3rem;
	}

	.main-slidesshow ~ .slider-arrow .slider-arrow-icon {
		display: block;
		margin-right: auto;
		margin-left: auto;
		color: #fff;
	}

	.main-slidesshow .slide {
		background-color: #f1f1f1;
	}

	.main-slidesshow .slide-image {
		width: 100%;
		height: 48vw;
	}

	.main-slidesshow .slide-caption-wrapper {
		display: block;
		width: 35%;
		padding: 1rem 1.1rem 3.5rem 1.3rem;
		background-color: rgba(241, 241, 241, 0.8);
	}

	.main-slidesshow .slide-caption-wrapper p,
	.main-slidesshow .slide-caption-wrapper li,
	.main-slidesshow .slide-caption-wrapper span {
		font-size: 0.9rem;
		line-height: 1.4rem;
	}

	.main-slidesshow .slide-caption-header {
		width: auto;
		max-width: 310px;
		min-width: 232px;
		font-size: 1.4em;
		line-height: 1.8rem;
	}

	.main-slidesshow .slide-caption-paragraph,
	.main-slidesshow .slide-caption-wrapper p {
		overflow: hidden;
		width: 100%;
		max-height: 170px;
		font-size: 0.9rem;
		line-height: 1.5em;
	}

	.main-slidesshow .slide .more-link {
		margin-top: 0rem;
	}
}

@media (max-width: 767px) {
	.main-slidesshow {
		height: auto;
	}

	.main-slidesshow ~ .slider-arrow {
		width: 2rem;
		height: 2rem;
	}

	.main-slidesshow ~ .slider-arrow.w-slider-arrow-left {
		width: 2rem;
		height: 2rem;
		margin-right: 2.5rem;
	}

	.main-slidesshow ~ .slider-arrow .slider-arrow-icon {
		font-size: 1rem;
	}

	.main-slidesshow .slide {
		position: relative;
		background-color: transparent;
		background-image: none;
	}

	.main-slidesshow .slide-image {
		height: 40vw;
	}

	.main-slidesshow .slide-caption-wrapper {
		position: static;
		display: block;
		width: 100%;
		padding-bottom: 2.5rem;
		background-image: none;
	}

	.main-slidesshow .slide-caption-wrapper p,
	.main-slidesshow .slide-caption-wrapper li,
	.main-slidesshow .slide-caption-wrapper span {
		font-size: 0.83rem;
		line-height: 1.25rem;
	}

	.main-slidesshow .slide-caption-header {
		font-size: 1.2em;
		line-height: 1.1em;
	}
}

@media (max-width: 479px) {
	.main-slidesshow ~ .slider-arrow.w-slider-arrow-left {
		margin-right: 2.5rem;
	}

	.main-slidesshow .slide-image {
		height: 43vw;
	}

	.main-slidesshow .slide-caption-header {
		line-height: 1.3em;
	}

	.main-slidesshow .slide-caption-header {
		font-size: 1.3em;
		line-height: 1.55rem;
	}
}

/* Alert Box */
.special-notice-alert-box {
	position: relative;
	display: none;
	margin-right: auto;
	margin-left: auto;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
	background-image: linear-gradient(180deg, #fff, #fff);
	box-shadow: none;
	overflow: hidden;
}

.special-notice-alert-box .special-notice-container-2 {
	position: relative;
	display: flex;
	height: auto;
	min-height: 52px;
	border-bottom: 3px solid #dbc368;
	background-color: #001489;
	color: #151618;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 600;
	text-transform: none;
	justify-content: space-between;
}

.special-notice-alert-box .special-notice-title-holder-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 150px;
	min-height: 50px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #ba0c2f;
}

.special-notice-alert-box .special-notice-title-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	padding-right: 6px;
	padding-left: 6px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
}

.special-notice-alert-box .div-block-11 {
	position: relative;
	z-index: 1;
	width: 80px;
	height: 40px;
	border: 1.5px solid #fff;
	background-color: #001489;
	align-self: center;
}

.special-notice-alert-box .special-notice-close-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 40px;
	padding: 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1.5px solid #fff;
	background-color: transparent;
	cursor: pointer;
	margin-right: 20px;
	margin-left: 11px;
	align-self: center;
}

.special-notice-alert-box .special-notice-text-holder-2 {
	padding-left: 20px;
	width: calc(100% - 300px);
	min-height: 37px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 20px;
}

.special-notice-alert-box .slider-4 {
	height: auto;
	background-color: transparent;
	text-align: left;
	display: flex;
	align-items: center;
	min-height: auto;
	justify-content: center;
	width: 100%;
	overflow: hidden;
}

.special-notice-alert-box .left-arrow-3 {
	left: auto;
	right: 37px;
	display: block;
	width: 40px;
}

.special-notice-alert-box .icon-6 {
	font-size: 1.5rem;
}

.special-notice-alert-box .right-arrow-3 {
	display: block;
	width: 40px;
}

.special-notice-alert-box .icon-7 {
	font-size: 1.5rem;
}

.special-notice-alert-box .slider-alert {
	margin-left: 0px;
	padding-right: 0px;
	display: none;
	height: auto;
}

.special-notice-alert-box .slider-alert *,
.special-notice-alert-box .slider-alert p,
.special-notice-alert-box .slider-alert p * {
	color: #fff !important;
	background-color: transparent;
}

.special-notice-alert-box .slider-alert p,
.special-notice-alert-box .slider-alert p * {
	line-height: 1.4rem;
}

.special-notice-alert-box .slider-alert p a {
	color: #549fc4 !important;
	text-decoration: underline;
	transition: none;
	background-color: transparent;
}

.special-notice-alert-box .slider-alert p a:hover {
	color: #fff !important;
	text-decoration: underline;
	background-color: transparent;
}

.special-notice-alert-box .slider-alert p:last-child {
	margin-bottom: 0px;
}

.special-notice-alert-box .div-block-10 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.special-notice-alert-box .div-block-8 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.special-notice-alert-box .text-block-9 {
	color: #fff;
	font-size: 1rem;
	text-align: center;
}

.special-notice-alert-box .bold-text-2 {
	font-size: 1rem;
	line-height: 1.1rem;
	font-weight: bold;
	text-align: center;
}

@media screen and (max-width: 991px) {
	.special-notice-alert-box .special-notice-title-holder-2 {
		width: 100px;
	}

	.special-notice-alert-box .special-notice-title-2 {
		line-height: 1.2rem;
	}

	.special-notice-alert-box .div-block-11 {
		width: 63px;
	}

	.special-notice-alert-box .special-notice-close-2 {
		width: 32px;
		margin-right: 10px;
	}

	.special-notice-alert-box .special-notice-text-holder-2 {
		width: calc(100% - 225px);
		padding-left: 35px;
		padding-right: 7px;
	}

	.special-notice-alert-box .slider-4 {
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
	}

	.special-notice-alert-box .left-arrow-3 {
		width: 30px;
		right: 30px;
	}

	.special-notice-alert-box .icon-6 {
		margin-right: 0px;
		margin-left: 6px;
		font-size: 1.2rem;
	}

	.special-notice-alert-box .right-arrow-3 {
		width: 30px;
	}

	.special-notice-alert-box .icon-7 {
		margin-right: 0px;
		margin-left: 4px;
		font-size: 1.2rem;
	}

	.special-notice-alert-box .slider-alert {
		padding-right: 0;
	}

	.special-notice-alert-box .div-block-10 {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.special-notice-alert-box .div-block-8 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.special-notice-alert-box .text-block-9 {
		font-size: 0.7rem;
		line-height: 1.2rem;
	}

	.special-notice-alert-box .bold-text-2 {
		color: #fff;
		font-size: 0.8rem;
		line-height: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.special-notice-alert-box .special-notice-container-2 {
		min-height: 150px;
		height: auto;
		margin-right: auto;
		flex-wrap: wrap;
		padding: 0px 0px 10px 0px;
	}

	.special-notice-alert-box .special-notice-title-holder-2 {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		width: 100%;
		height: 10%;
		min-height: 30px;
	}

	.special-notice-alert-box .special-notice-title-2 {
		font-size: 0.9rem;
		line-height: 1rem;
		text-align: right;
	}

	.special-notice-alert-box .div-block-11 {
		position: relative;
		left: auto;
		right: 0px;
		width: 63px;
		height: 30px;
	}

	.special-notice-alert-box .special-notice-close-2 {
		width: 32px;
		height: 30px;
		margin-right: 10px;
	}

	.special-notice-alert-box .special-notice-text-holder-2 {
		flex-grow: 1;
		margin-top: 0px;
		margin-left: 10px;
		padding-right: 0px;
		padding-left: 0px;
		min-height: 70px;
	}

	.special-notice-alert-box .slider-4 {
		margin-left: 0px;
		padding-top: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
	}

	.special-notice-alert-box .left-arrow-3 {
		width: 30px;
		right: 30px;
	}

	.special-notice-alert-box .icon-6 {
		margin-left: 5px;
		font-size: 1.2rem;
	}

	.special-notice-alert-box .right-arrow-3 {
		width: 30px;
	}

	.special-notice-alert-box .icon-7 {
		margin-left: 5px;
		font-size: 1.2rem;
	}

	.special-notice-alert-box .slider-alert {
		overflow: hidden;
		padding-right: 5px;
	}

	.special-notice-alert-box .div-block-10 {
		margin-right: auto;
	}

	.special-notice-alert-box .div-block-8 {
		overflow: visible;
		width: 100%;
		margin-left: 0px;
		padding: 0px;
		font-size: 0.8rem;
	}

	.special-notice-alert-box .text-block-9 {
		font-size: 0.7rem;
		line-height: 1.2rem;
	}

	.special-notice-alert-box .bold-text-2 {
		margin-left: 0px;
	}

	.special-notice-alert-box .slider-alert *,
	.special-notice-alert-box .slider-alert p,
	.special-notice-alert-box .slider-alert p * {
		line-height: 1.2rem;
	}
}

@media screen and (max-width: 479px) {
	.special-notice-alert-box .special-notice-container-2 {
		min-height: 175px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.special-notice-alert-box .special-notice-title-holder-2 {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		width: 100%;
		min-height: 10%;
		height: auto;
		margin-bottom: 0px;
	}

	.special-notice-alert-box .special-notice-title-2 {
		padding-right: 2px;
		padding-bottom: 0px;
		padding-left: 2px;
		font-size: 0.8rem;
		line-height: 0.9rem;
		text-align: center;
		letter-spacing: -0.9px;
	}

	.special-notice-alert-box .div-block-11 {
		position: relative;
		left: auto;
		right: 0px;
		width: 63px;
		height: 30px;
		margin-right: 15px;
	}

	.special-notice-alert-box .special-notice-close-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 30px;
		margin-right: 12px;
		margin-left: 0px;
	}

	.special-notice-alert-box .special-notice-text-holder-2 {
		margin-top: 5px;
		margin-right: 10px;
		margin-left: 10px;
		padding-left: 0px;
	}

	.special-notice-alert-box .slider-alert {
		overflow: hidden;
		padding-right: 0px;
	}

	.special-notice-alert-box .div-block-10 {
		margin-right: 0px;
		margin-left: 0px;
	}

	.special-notice-alert-box .div-block-8 {
		width: 100%;
	}

	.special-notice-alert-box .text-block-9 {
		color: #fff;
		text-align: left;
	}

	.special-notice-alert-box .bold-text-2 {
		color: #fff;
		font-size: 0.7rem;
		text-align: left;
	}
}


/* Donations button */
/* @keyframes donations-image {
	0% {
		transform: scale(1);
	}

	10% {
		transform: scale(1.05);
	}

	20% {
		transform: scale(1.1);
	}

	30% {
		transform: scale(1.15);
	}

	40% {
		transform: scale(1.2);
	}

	50% {
		transform: scale(1.25);
	}

	60% {
		transform: scale(1.3);
	}

	70% {
		transform: scale(1.35);
	}

	80% {
		transform: scale(1.4);
	}

	90% {
		transform: scale(1.45);
	}

	100% {
		transform: scale(1.5);
	}
} */

@keyframes donations-image {
	0% {
		transform: scale(1.1);
	}

	50% {
		transform: scale(1.5);
	}

	100% {
		transform: scale(1.1);
	}
}

@keyframes donations-ripple {
	0% {
		transform: translate(-50%, -50%) scale(0);
		opacity: 0;
	}

	30% {
		transform: translate(-50%, -50%) scale(0);
		opacity: 1;
	}

	100% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 0;
	}
}

.donations-btn {
	display: flex;
	background-color: #bf1e3e;
	box-shadow: none;
	margin-top: 1em;
	margin-bottom: 0.25em;
	transition: all 300ms ease;
	padding: 0.8rem 1rem 0.8rem 1rem;
	border-radius: 6px;
	text-decoration: none;
	overflow: hidden;
}

.donations-btn .donations-btn-image {
	padding-right: 0.5rem;
	font-size: 0.85rem;
	display: flex;
	align-items: center;
	position: relative;
}

.donations-btn .donations-btn-image img {
	animation-name: donations-image;
	animation-duration: 4s;
	animation-iteration-count: 100;
	transition: all 2s linear;
}

.donations-btn .donations-btn-image .donations-btn-ripple {
	animation-name: donations-ripple;
	animation-duration: 4s;
	animation-iteration-count: 100;
	transition: all 2s ease-out;
	width: 344px;
	height: 344px;
	background-color: rgba(255, 255, 255, 0.2);
	transform: translate(-50%, -50%) scale(0);
	opacity: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	z-index: 1;
}

.donations-btn .donations-btn-text {
	color: #fff;
	font-size: 0.85rem;
	text-align: center;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-decoration: none;
}

.donations-btn:hover {
	background-color: #001489;
	color: #fff;
}
